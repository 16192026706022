var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "personal-discounts" },
    [
      _c(
        "div",
        { staticClass: "text-body-0 primary--text font-weight-semibold my-4" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("coupons.galery.active", { number: _vm.activeCoupons })
              ) +
              " "
          )
        ]
      ),
      _vm.coupons && _vm.coupons.length > 0
        ? _c(
            "v-row",
            _vm._l(_vm.coupons, function(coupon, index) {
              return _c(
                "v-col",
                { key: "coupon-" + index, attrs: { cols: "12", sm: "6" } },
                [
                  _c("ProductCard", {
                    attrs: {
                      product: coupon,
                      type: coupon.metaData.voucher_info.selected
                        ? "selected"
                        : "available"
                    },
                    on: {
                      openDetails: _vm.openDetails,
                      reload: _vm.loadCoupons
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "text-center font-weight-semibold secondary--text text-body-0 py-6"
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.type == "available"
                      ? _vm.$t("coupons.galery.noAvailable")
                      : _vm.$t("coupons.galery.noActive")
                  ) +
                  " "
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }